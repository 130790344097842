"use strict"

export class StorageBase {
	static toStr = (value) => (value === Object(value) ? JSON.stringify(value) : "" + value)
	static toObj = (value) => {
		let result = {}
		try {
			result.obj = JSON.parse(value)
		} catch (e) {
			result.obj = value
		}
		return result
	}

	static getByKey = (key) => window.localStorage.getItem(StorageBase.toStr(key))
	static getObjectByKey = (key) => JSON.parse(StorageBase.getByKey(key))

	static getByKeyQuery = (expression) =>
		Object.entries(window.localStorage).filter((entry) => expression(StorageBase.toObj(entry[0]).obj))
	static getByValueQuery = (expression) =>
		Object.entries(window.localStorage).filter((entry) => expression(StorageBase.toObj(entry[1]).obj))
	static getByQuery = (expression) =>
		Object.entries(window.localStorage).filter((entry) =>
			expression(StorageBase.toObj(entry[0]).obj, StorageBase.toObj(entry[1]).obj)
		)

	static getAll = () => Object.entries(window.localStorage)

	static exists = (key) => isSafe(StorageBase.getByKey(key))

	static updateByKey = (key, value) => window.localStorage.setItem(StorageBase.toStr(key), StorageBase.toStr(value))

	static deleteByKey = (key) => window.localStorage.removeItem(StorageBase.toStr(key))
	static deleteByKeyQuery = (expression) =>
		StorageBase.getByKeyQuery(expression).forEach((x) => StorageBase.deleteByKey(x[0]))
	static deleteByValueQuery = (expression) =>
		StorageBase.getByValueQuery(expression).forEach((x) => StorageBase.deleteByKey(x[0]))
	static deleteByQuery = (expression) =>
		StorageBase.getByQuery(expression).forEach((x) => StorageBase.deleteByKey(x[0]))
	static deleteAll = () => window.localStorage.clear()
}

export default class StorageEx {
	static getByKey = (key) => {
		let result = null
		const value = StorageBase.getByKey(key)
		if (isSafe(value)) {
			result = JSON.stringify(JSON.parse(value).data)
		}
		return result
	}

	static getInfoByKey = (key) => {
		let result = null
		const value = StorageBase.getByKey(key)
		if (isSafe(value)) {
			result = JSON.stringify(JSON.parse(value).info)
		}
		return result
	}

	static exists = (key) => StorageBase.exists(key)

	static updateByKey = (key, value) => {
		StorageBase.updateByKey(key, {
			info: {LastModified: Date.now()},
			data: value === Object(value) ? value : JSON.parse(value)
		})
	}

	static deleteByKey = (key) => StorageBase.deleteByKey(key)
}
