// @flow
import React from "react"
import {observer} from "mobx-react"
// import {withStyles} from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"

import GlobalStore from "../../store/GlobalStore"

import "./loading.less"

// const styles = (theme) => ({
// 	progress: {
// 		margin: theme.spacing.unit * 2
// 	}
// })

const renderLoading = (size, darkText) => {
	return (
		<div className="loading">
			<div className="progressMessage">
				<CircularProgress /*className={classes.progress}*/ size={size || 50} />
				{GlobalStore.loadingMessage && (
					<div className={"message" + darkText ? " dark" : ""}>{GlobalStore.loadingMessage}</div>
				)}
			</div>
		</div>
	)
}

const xsLoading = ({size, overlay}) => {
	if (overlay) {
		return <Modal open={true}>{renderLoading(size)}</Modal>
	} else {
		return renderLoading(size)
	}
}

export default observer(xsLoading)
