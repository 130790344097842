"use strict"

import {observable, action} from "mobx"

class InfoStore {
	@observable isOpen = false

	@observable content = null
	@observable title = null
	@observable actions = null

	@action open(content = null, title = null, actions = null) {
		this.content = content
		this.title = title
		this.actions = actions

		this.isOpen = true
	}

	@action close() {
		this.content = null
		this.title = null
		this.actions = null

		this.isOpen = false
	}
}

var singleton = new InfoStore()
export default singleton
