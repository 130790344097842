import {observable, action} from "mobx"
import api from "../../global/actions/api"
import {getUserCompanyInfo} from "../../global/helpers/actions"
import {getFilters} from "../../global/helpers/api"
import {printReport} from "../../global/helpers/actions"
// import GlobalStore from "../../global/store/GlobalStore"
// import RouterStore from "../../global/store/RouterStore"

class SolverRequestsStore {
	@observable listData = []

	@action loadListData() {
		const filter = getFilters([`company_id=${getUserCompanyInfo()._id}`])

		api
			.loadSolverRequests(filter)
			.call()
			.then((response) => {
				this.listData = response.rows
			})
	}

	@action exportList() {
		let rows = ""

		this.listData.forEach((row, index) => {
			const company_data = row.company_data || {}
			const company_address = company_data.oa_address || {}

			rows += `
		<tr>
			<td style="width: 3%;">
				<p>${index + 1}.</p>
			</td>
			<td style="width: 15%;">
				<p>${row.company_data.name}</p>
			</td>
			<td style="width: 15%;">
				<p>${company_address.full_address}</p>
			</td>
			<td style="width: 15%;">
				<p>${row.request_contact_data.name}</p>
			</td>
			<td style="width: 5%;">
				<p>${row.company_data.identifier}</p>
			</td>
			<td style="width: 5%;">
				<p>${row.company_data.tax_identification_number}</p>
			</td>
			<td style="width: 5%;">
				<p>${row.company_data.vat_identification_number}</p>
			</td>
			<td style="width: 12%;">
				<p>Áno</p>
			</td>
			<td style="width: 25%;">
				<p>${row.orsr_activity_subject}</p>
			</td>
		</tr>	
			`
		})

		let printValues = {table_rows: rows}
		printReport("solverRequests", printValues,null,null,"-L")
	}
}

var singleton = new SolverRequestsStore()
export default singleton
