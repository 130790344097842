import {useState, useEffect, useRef} from "react"

import {StorageBase} from "../storage/storageEx"
import RouterStore from "../store/RouterStore"
import GlobalStore from "../store/GlobalStore"

import {USER_DATA} from "../config/constants"
import config from "../config/settings"
import {load} from "recaptcha-v3"
import api from "../actions/api"

export const getUser = () => StorageBase.getObjectByKey(USER_DATA)

export const login = (username, password) => {
	GlobalStore.loading(true, "Prihlasujem")
	try {
		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("security_login").then((recaptchaToken) => {
				api
					.login(recaptchaToken, username, password)
					.call((statusCode) => {
						if (statusCode != 200) {
							GlobalStore.authentificationFailed = true
						}
					})
					.then((user) => {
						if (typeof user == "undefined") {
							GlobalStore.authentificationFailed = true
							throw new Error()
						}

						const days40 = 40 * 24 * 60 * 60 * 1000
						StorageBase.deleteByValueQuery((x) => isSafe(x.info) && x.info.LastModified < Date.now() - days40)

						StorageBase.updateByKey(USER_DATA, user)

						GlobalStore.loading(false)

						if (isUserSolver()) {
							RouterStore.push("/solverRequests")
						} else {
							RouterStore.push("/calls")
						}
					})
			})
		})
	} catch (err) {
		logger(err)
	}
}

//Zavolá mPDF API a pošle HTML alebo názov template
export const printReport = (templateName = null, values, directHtml, streamCallback, format = null) => {
	const user = getUser() || {}

	const requestData = {
		_html: directHtml,
		_template: templateName,
		_format: format,
		_stream: streamCallback ? true : false,
		_token: `SIEA ${user.sieatoken || ""}`,
		_apiBaseUri: config.API_BASE_URL,
		_paddingTop: 0,
		values: values
	}

	printMPDF(streamCallback, requestData)
}

export const printMPDF = (streamCallback, requestData) => {
	if (streamCallback) {
		fetch(config.MPDF_API, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(requestData)
		})
			.then((response) => response.blob())
			.then((blob) => streamCallback(blob))
	} else {
		openWindowWithPost(config.MPDF_API, requestData)
	}
}

export const openWindowWithPost = (url, data) => {
	let form = document.createElement("form")
	form.target = "_blank"
	form.method = "POST"
	form.action = url
	form.style.display = "none"

	for (let key in data) {
		const value = data[key]

		if (isSafe(value) && typeof value == "object") {
			for (let k in value) {
				let input = createHiddenInput(k, value[k])
				form.appendChild(input)
			}
		} else {
			let input = createHiddenInput(key, value)
			form.appendChild(input)
		}
	}
	document.body.appendChild(form)
	form.submit()
	document.body.removeChild(form)
}

export const createHiddenInput = (key, value) => {
	let input = document.createElement("input")
	input.type = "hidden"
	input.name = key
	input.value = value
	return input
}

export const getPerson = () => {
	const user = getUser()

	if (isSafe(user.person)) {
		return user.person
	}

	return null
}

export const getPersonId = () => {
	const user = getUser()

	if (isSafe(user.person)) {
		return user.person._id
	}

	return null
}

export const logout = async () => {
	GlobalStore.loading(true, "Logout in progress...")

	try {
		await api.logout().call()

		StorageBase.deleteByKey(USER_DATA)

		GlobalStore.loading(false)

		RouterStore.push("/login")
	} catch (err) {
		logger(err)
	}
}

export const echo = () => {
	api.getEcho().call()
}

export const useInterval = (callback, delay) => {
	const savedCallback = useRef()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

export const mergeObj = (target, source) => {
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object && key in target) Object.assign(source[key], mergeObj(target[key], source[key]))
	}
	Object.assign(target || {}, source)
	return target
}

export const useMergeState = (initialState) => {
	const [state, setState] = useState(initialState)
	const setMergedState = (newState) => setState((prevState) => mergeObj(prevState, newState))
	return [state, setMergedState]
}

// Vráti objekt s informáciami o poskytovateľovi
export const getUserCompanyInfo = () => {
	const company = getUser().company
	return company ? {...company, id: company._id} : null
}

export const getFieldIdentifier = (field, additional = "") => {
	let customClassName = additional
	if (field) {
		const fieldName = field.name || field.key
		customClassName = customClassName === "" ? fieldName : `${customClassName} ${fieldName}`
	}
	return customClassName
}

// Vráti string s nazvom role pouzivatela
export const getUserRole = () => {
	const user = getUser()
	if (user.is_applicant == true) {
		return "is_applicant"
	} else {
		return "is_solver"
	}
}

// Vráti ci je user riesitel
export const isUserSolver = () => {
	const user = getUser()
	return user.is_solver == true
}

// Vráti ci je user riesitel
export const isUserApplicant = () => {
	const user = getUser()
	return user.is_applicant == true
}

// Vráti zoznam obmedzeni na ktore vyzvy ziadatel nemoze podat ziadost
export const getUserCallRestriction = () => {
	const user = getUser()
	if (isSafe(user.call_restriction) && Array.isArray(user.call_restriction)) {
		return user.call_restriction.map((row) => row.call_type_code)
	} else return []
}
