import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import fields from "./solverRequestFields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import api from "../../global/actions/api"
import {Container, Grid, Typography /*, Link */} from "@material-ui/core"
import ReactTooltip from "react-tooltip"
import {injectIntl} from "react-intl" //FormattedMessage,
import XsInput from "../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import dvr from "mobx-react-form/lib/validators/DVR"
import GlobalStore from "../../global/store/GlobalStore"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../global/ui/xsButton/xsButton"
// import InfoStore from "../../global/store/InfoStore"
import RouterStore from "../../global/store/RouterStore"
// import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
// import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import {getUser, getUserCompanyInfo} from "../../global/helpers/actions"
import SolverRequestsDetailStore from "./solverRequestsDetailStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import renderHTML from "react-render-html"

function solverRequestsDetail(props) {
	const hooks = {
		onSubmit(form) {
			if (form.$("companyRegistryDate").value == false) {
				form.$("companyRegistryDate").invalidate()
			}
		},
		onSuccess(form) {
			SolverRequestsDetailStore.saveForm(form, "final", () => {
				RouterStore.push("/solverRequests")
				GlobalStore.loading(false)
			})
		},
		onError(form) {
			logger("CHYBY:", form.errors())
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const isFormDisabled = () => {
		return Boolean(form.$("status").value != "inp" && isNotEmpty(form.$("status").value))
	}

	const initNewForm = async () => {
		await RegistersCacheStore.checkRegisters("/request")
		const company = getUserCompanyInfo() || {}
		const user = getUser() || {}
		form.$("companyName").value = company.name
		form.$("ico").value = company.identifier
		form.$("contactPersonMail").value = user.user.email
	}

	useEffect(() => {
		if (isNotEmpty(props.id)) {
			SolverRequestsDetailStore.loadCodelistAndForm(form, props.id)
		} else {
			initNewForm()
		}

		return () => {
			form.reset()
		}
	}, [])

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Container maxWidth="lg" className="request_form_fields">
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h5" align="center">
								Žiadosť o zaradenie do zoznamu oprávnených riešiteľov inovačných projektov v rámci NP ZIVSE
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Identifikácia oprávneného žiadateľa</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="companyName">
							<XsInput field={form.$("companyName")} disabled={true} />
							<ReactTooltip id="companyName">
								Uveďte obchodné meno v súlade s ORSR, ŽRSR. V prípade slobodných povolaní vypĺňa fyzická osoba svoje
								celé meno a priezvisko.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="ico">
							<XsNumberInput field={form.$("ico")} step={"1"} min={"1"} disabled={true} />
							<ReactTooltip id="ico">
								V prípade, že máte IČO, povinne ho vyplňte. Správne uvedenie IČO a DIČ je nevyhnutné pre registráciu
								Vašej žiadosti.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("dic")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="icdph">
							<XsInput field={form.$("icdph")} disabled={isFormDisabled()} />
							<ReactTooltip id="icdph">
								V prípade, že máte IČ DPH, je potrebné ho uviesť. V prípade, že IČ DPH nemáte, je potrebné uviesť do
								bunky IČ DPH nulu.
							</ReactTooltip>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">Adresa sídla podnikania</Typography>
						</Grid>
						{/* <Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyRegion")}
								items={GlobalStore.CL["region"]}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									// form.$("companyCounty").value = ""
									form.$("companyCity").value = ""
								}}
							/>
						</Grid> */}
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("companyCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreet")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreetNumber")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("companyPSC")} min={"1"} step={"1"} disabled={isFormDisabled()} />
						</Grid>

						<Grid item xs={12}>
							<Typography variant="h6" data-tip data-for="contactPersonText">
								Kontaktná osoba žiadateľa
							</Typography>
							<ReactTooltip id="contactPersonText">
								Osoba, ktorá zabezpečuje komunikáciu vo veci všetkých aktivít národného projektu ZIVSE, ktorých sa bude
								Váš podnik zúčastňovať.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonPhone")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonMail")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("webAddress")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<XsCheckbox field={form.$("companyRegistryDate")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<XsTextArea
								field={form.$("companyTypeInRegistry")}
								disabled={isFormDisabled()}
								required
								tooltip={renderHTML(
									`Uveďte predmety činností spolu s dátumom registrácie (na základe informácií uvedených v ORSR/ŽRSR), ktoré Vašu spoločnosť oprávňujú pre poskytovanie služieb v oblasti podnikateľského poradenstva,<br />
									 vzdelávania alebo činnosti zameraných na výskum a vývoj, a to: podnikateľské poradenstvo, výskum a vývoj v oblasti prírodných a technických vied, výskum a vývoj v oblasti humanitných a spoločenských vied,<br />
									činnosť podnikateľských, organizačných a ekonomických poradcov, prieskum trhu a verejnej mienky. Uvedené činnosti musia byť zapísané v ORSR/ŽRSR<br />
									minimálne 6 mesiacov pred dátumom odoslania žiadosti pre zaradenie do zoznamu oprávnených riešiteľov.`
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Konflikt záujmov (popis a podmienky)</Typography>
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<XsCheckbox field={form.$("conflict1")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<XsCheckbox field={form.$("conflict2")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							<br />
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="lg" className="request_form_buttons">
					<Grid item container spacing={1}>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										SolverRequestsDetailStore.saveForm(form, "draft", () => {
											RouterStore.push("/solverRequests")
											GlobalStore.loading(false)
										})
										// RequestFormStore.validateDraft(form, () => {
										// 	RequestFormStore.generateDataAndSave(form, "draft", (id) => {
										// 		RouterStore.push(`/request?id=${id}`)
										// 		GlobalStore.loading(true)
										// 		RequestFormStore.resetDropzones()
										// 		form.reset()
										// 		RequestFormStore.load(form, id)
										// 	})
										// })
									})
								}}
								disabled={
									!(form.$("status").value == "inp" || isEmpty(form.$("status").value)) ||
									GlobalStore.isRegistrationClose()
								}
								text={"Uložiť rozpracovanú žiadosť"}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={(e) => {
									delayedCallback(300, () => {
										form.onSubmit(e)
									})
								}}
								disabled={
									form.$("status").value == "sent" ||
									form.$("conflict1").value == false ||
									form.$("conflict2").value == false ||
									form.$("companyRegistryDate").value == false
								}
								text="Odoslať vypracovanú žiadosť (bez možnosti zmeny)"
							/>
						</Grid>
						{/* <Grid item>
							<XsButton
								disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										// RequestPrintStore.print(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ PDF ŽIADOSTI"}
							/>
						</Grid> */}
						<Grid item>
							{/* <XsButton
								// disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.generateExcel(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ EXCEL ŽIADOSTI"}
							/> */}
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-times"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										form.reset()
										RouterStore.push("/solverRequests")
									})
								}}
								color="secondary"
								text={"Zatvoriť a zrušiť zmeny"}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<XsConfirmationDialog
				name="xsCloseRequestForm"
				text="Naozaj chcete opustiť formulár a zahodť zmeny?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(solverRequestsDetail))
