import {observable, action} from "mobx"
import api from "../../global/actions/api"
// import GlobalStore from "../../global/store/GlobalStore"
// import RouterStore from "../../global/store/RouterStore"

class CallsStore {
	@observable listData = []

	@action loadListData() {
		api
			.loadCalls()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.listData = response.rows.sort((rowA, rowB) => {
						const created_at_A = rowA.From
						const created_at_B = rowB.From
						if (created_at_A > created_at_B) return -1
						if (created_at_B > created_at_A) return 1
						return 0
					})
				} else {
					this.listData = []
				}
			})
	}
}

var singleton = new CallsStore()
export default singleton

