import React from "react"
import {observable, action} from "mobx"
import api from "../../global/actions/api"
import {Grid, Typography, Link} from "@material-ui/core"
import moment from "moment"

class CallDetailStore {
	@observable data = {}
	@observable restrictionBA = ""

	@action loadCallInfo(callId) {
		api
			.loadCalls()
			.call()
			.then((response) => {
				this.data = response.rows.find((row) => row.ID == callId)
				if (this.data.CallTypeCode == "IP_SK_121") {
					this.checkRegionAvailability(this.data.ID)
				}
			})
	}

	checkRegionAvailability(callId) {
		if (isNotEmpty(callId)) {
			const req = {
				region_id: "SK010",
				call_id: callId
			}

			api
				.regionRestriction(req)
				.call()
				.then((response) => {
					if (isSafe(response) && response.is_allowed === false) {
						this.restrictionBA = (
							<React.Fragment>
								<br />
								<Grid item>
									<Typography variant="h5" className="redDanger">
										Dôležitá informácia:
									</Typography>
								</Grid>
								<br />
								<Grid item>
									<Typography variant="h6" className="redDanger">
										Slovenská inovačná a energetická agentúra (ďalej len „SIEA“) Vám oznamuje, že odo dňa{" "}
										{isSafe(response.allowed_to) && moment(response.allowed_to).format("DD.MM.YYYY")} od{" "}
										{isSafe(response.allowed_to) && moment(response.allowed_to).format("HH:mm")} hod. bude v rámci
										národného projektu Zvýšenie inovačnej výkonnosti slovenskej ekonomiky dočasne pozastavené
										predkladanie/príjem žiadostí o Inovačnú poukážku (ďalej len „ŽoIP“) v rámci Výzvy IP_SK_121 z dôvodu
										dostatočného množstva predložených ŽoIP v rámci Bratislavského kraja s ohľadom na indikatívnu výšku
										finančných prostriedkov vyčlenených na Výzvu.
									</Typography>
								</Grid>
								<br />
								<Grid item>
									<Typography variant="h6" className="redDanger">
										V prípade vyčerpania indikatívnej výšky finančných prostriedkov bude oznámené ukončenie možnosti
										predloženia ŽoIP zo sídlom žiadateľov z Bratislavského kraja na webovom sídle inovujme.sk. V prípade
										uvoľnenia zdrojov v rámci indikatívnej výšky finančných prostriedkov bude predkladanie ŽoIP zo
										sídlom žiadateľov z Bratislavského kraja opätovne spustené v rámci ďalšieho hodnotiaceho kola, čo
										bude včas zverejnené na webovom sídle
									</Typography>
									<Link
										className="pointer"
										variant="h6"
										onClick={() => window.open("https://www.inovujme.sk", "_blank")}
									>
										https://www.inovujme.sk
									</Link>
								</Grid>
							</React.Fragment>
						)
					}
				})
		}
	}
}

var singleton = new CallDetailStore()
export default singleton
