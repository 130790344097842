import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import moment from "moment"
import SolverRequestsStore from "./solverRequestsStore"
import {Container, Box, Grid, Typography, Link} from "@material-ui/core"
import XsTable from "../../global/ui/xsTable/xsTable"
import RouterStore from "../../global/store/RouterStore"
import XsButton from "../../global/ui/xsButton/xsButton"
import {getUserRole, isUserApplicant, isUserSolver} from "../../global/helpers/actions"

function solverRequestsList() {
	const columns = [
		{
			Header: "Dátum odoslania žiadosti",
			width: 150,
			accessor: "sent_date_time",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.sent_date_time
				const sent_date_time_B = rowB.original.sent_date_time
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Obchodné meno",
			width: 150,
			accessor: "company_data.name",
			sortType: (rowA, rowB) => {
				const send_date_time_A = isNotEmpty(rowA.original.To) ? rowA.original.To : ""
				const send_date_time_B = isNotEmpty(rowB.original.To) ? rowB.original.To : ""
				if (send_date_time_A > send_date_time_B) return -1
				if (send_date_time_B > send_date_time_A) return 1
				return 0
			}
		},
		{
			Header: "IČO",
			width: 120,
			accessor: "company_data.identifier"
		},
		{
			Header: "Kontaktná osoba",
			width: 200,
			accessor: "request_contact_data.name",
			sortType: "basic"
		},
		{
			Header: "Stav žiadosti ",
			width: 150,
			accessor: "status_name_ext",
			sortType: "basic"
		}
	]

	useEffect(() => {
		SolverRequestsStore.loadListData()

		logger(getUserRole())
		logger(isUserSolver())
		logger(isUserApplicant())
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							Zoznam žiadostí
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Nachádzate sa na stránke, prostredníctvom ktorej sa môžete zapojiť do otvorenej výzvy na predkladanie
						žiadostí o zaradenie do zoznamu oprávnených riešiteľov inovačných projektov v rámci NP ZIVSE.
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						V prípade požiadavky o zmenu údajov uvedených v žiadosti prosím kontaktujte náš tím na e-mailovej adrese:{" "}
						<Link className="pointer" onClick={() => window.open("mailto:inovacnepoukazky@siea.gov.sk")}>
							inovacnepoukazky@siea.gov.sk
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Dokumentáciu k výzve nájdete na odkaze:{" "}
						<Link className="pointer" onClick={() => window.open("https://www.inovujme.sk/sk/podniky ", "_blank")}>
							https://www.inovujme.sk/sk/podniky
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2" className="bold">
						Pre Výzvy s kódom IP_SK2_122 a IP_ZS_122 a neskôr vyhlásené Výzvy sa Zoznam riešiteľov neuplatňuje a nie je
						možné sa v Zozname riešiteľov registrovať.
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">Kliknutím na žiadosť si pozriete detail žiadosti</Typography>
				</Grid>
			</Grid>
			<Grid container direction="row-reverse">
				{/* <Grid item>
					<XsButton className="xs-info" text={"Export"} onClick={() => {SolverRequestsStore.exportList()}} />
				</Grid> */}
				<Grid item>
					<XsButton
						className="xs-info"
						text={"Pridať ziadosť"}
						onClick={() => RouterStore.push("/solverRequest")}
						disabled
					/>
				</Grid>
			</Grid>

			<Box pt={2}>
				<Grid container>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={SolverRequestsStore.listData}
								onRowClick={(row) => RouterStore.push(`/solverRequest?id=${row.record_id}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(solverRequestsList))
