import React from "react"
import {observer} from "mobx-react"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"

export default observer(
	({
		id,
		className,
		disabled,
		icon = "",
		onClick,
		tooltip,
		tabIndex,
		placement,
		delay,
		size = "small",
		variant = "contained",
		color = "primary"
	}) => {
		if (isSafe(tooltip)) {
			return (
				<Tooltip title={tooltip} enterDelay={delay} placement={placement}>
					<Button
						id={id}
						className={className}
						size={size}
						variant={variant}
						color={color}
						onClick={onClick}
						disabled={disabled}
						style={{minWidth: "32px"}}
						tabIndex={tabIndex}
					>
						{icon}
					</Button>
				</Tooltip>
			)
		} else {
			return (
				<Button
					id={id}
					size={size}
					variant={variant}
					color={color}
					className={className}
					onClick={onClick}
					disabled={disabled}
					style={{minWidth: "32px"}}
					tabIndex={tabIndex}
				>
					{icon}
				</Button>
			)
		}
	}
)
