import React, {Component} from "react"
import {observer} from "mobx-react"
import GlobalStore from "../.../../../store/GlobalStore"
import {withSnackbar} from "notistack"
import {injectIntl} from "react-intl"

import "./xsSnackbar.less"

@observer
class XsSnackbar extends Component {
	constructor(props) {
		super(props)
	}

	componentDidUpdate() {
		if (isNotEmpty(GlobalStore.notification.message)) {
			const message = this.props.intl.formatMessage({
				id: GlobalStore.notification.message
			})
			const variant = isNotEmpty(GlobalStore.notification.variant) ? GlobalStore.notification.variant : "default"

			this.props.enqueueSnackbar(message, {variant: variant})

			GlobalStore.notification = {
				message: "",
				variant: "default",
				data: ""
			}
		}
	}

	render() {
		GlobalStore.notification.message
		return <span data-snackbar={GlobalStore.notification.data} />
	}
}

export default withSnackbar(injectIntl(XsSnackbar))
