import {FormattedMessage} from "react-intl"
import React from "react"
import {getPerson, getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		const mail = isSafe(getUser()) && isSafe(getUser().user) ? getUser().user.email : ""
		return {
			fields: {
				_id: {},
				orgunit_id: {},
				status: {},
				dropzone: {},
				applicantRegion: {
					label: "Region",
					rules: "required"
				},
				applicantCountry: {
					label: "Country",
					rules: "required",
					value: "SK"
				},
				applicantCity: {
					label: "Town",
					rules: "required"
				},
				companyName: {
					label: <FormattedMessage id="Common.label.companyName" />,
					rules: "required"
				},
				// statutar: {
				// 	label: <FormattedMessage id="Common.label.statutarName" />,
				// 	rules: "required"
				// },
				companyStreet: {
					label: "Street"
				},
				companyStreetNumber: {
					label: "House number"
				},
				companyCity: {
					label: "Town (Start typing to fill the input)",
					rules: "required"
				},
				companyPSC: {
					label: "Zip",
					rules: "required"
				},
				company_id: {},
				ico: {
					label: <FormattedMessage id="Common.label.ico" />,
					rules: "required|between:8,8"
				},
				companyRegion: {
					label: "Region",
					rules: "required"
				},
				companyCounty: {
					label: "County",
					rules: "required"
				},
				// consortiumYes: {
				// 	label: <FormattedMessage id="Common.label.consortiumYes" />,
				// 	type: "checkbox"
				// },
				// multiCompanyName: {},
				// multiStatutar: {},
				// multiIco: {},

				// date: {
				// 	label: "Dátum",
				// 	type: "date",
				// 	rules: "date"
				// },
				//________-Kontaktné údaje žiadateľa
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required",
					value: getPerson().first_name
				},
				surname: {
					label: <FormattedMessage id="Common.label.surname" />,
					rules: "required",
					value: getPerson().last_name
				},
				person_id: {},
				mail: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "required|email",
					value: mail
				},
				phone: {
					label: <FormattedMessage id="Common.label.phone" />,
					rules: "required"
				},
				contactDescription: {
					label: "Company presentation (Brief introduction and relevant experience)",
					rules: "required"
				},
				contactCountry: {
					label: "Country",
					rules: "required",
					value: "SK"
				},

				//Activity type
				activityTypeRD: {
					label: "R&D",
					type: "checkbox"
				},
				activityTypeFID: {
					label: "FID",
					type: "checkbox"
				},

				//Applicable sector
				sectorAutomotive: {
					label: "Automotive",
					type: "checkbox"
				},
				sectorHealth: {
					label: "Health",
					type: "checkbox"
				},
				sectorIndustry: {
					label: "Industry 4.0",
					type: "checkbox"
				},
				sectorAdvancedPackagingAndTesting: {
					label: "Advanced packaging and testing",
					type: "checkbox"
				},
				sectorCompoundMaterials: {
					label: "Compound Materials",
					type: "checkbox"
				},
				sectorTelecommunication: {
					label: "Telecommunication",
					type: "checkbox"
				},
				sectorAeronautics: {
					label: "Aeronautics",
					type: "checkbox"
				},
				sectorEnergyEfficiencyChips: {
					label: "Energy Efficiency chips",
					type: "checkbox"
				},
				sectorSemiconductorManufacturing: {
					label: "Semiconductor manufacturing",
					type: "checkbox"
				},
				sectorInternetOfThings: {
					label: "Internet of things",
					type: "checkbox"
				},
				sectorCyberSecurity: {
					label: "Cyber security",
					type: "checkbox"
				},
				sectorSensors: {
					label: "Sensors",
					type: "checkbox"
				},
				sectorOther: {
					label: "Other (please specify)",
					type: "checkbox"
				},
				sectorOtherDescription: {
					label: "Specify"
				},

				//________-Udaje o projekte
				projectName: {
					label: "Project name (max. 1000 characters)",
					rules: "required"
				},
				// projectAcronym: {
				// 	label: <FormattedMessage id="Common.label.projectAcronym" />
				// },
				plannedProjectStart: {
					type: "date",
					label: "Obdobie pripravenosti"
				},
				plannedProjectStartYear: {
					label: "Planned project start date, Year",
					rules: "required"
				},
				plannedProjectStartMonth: {
					label: "Planned project start date, Month",
					rules: "required"
				},
				durationInMonth: {
					label: "Duration in months"
				},
				plannedInvestment: {
					label: "Planned Investment scale (CAPEX in millions EUR)",
					rules: "required"
				},
				plannedOpex: {
					label: "Estimated annual OPEX (in millions EUR)",
					rules: "required"
				},
				specificProjectDescription: {
					label: "Detailed Project Description",
					rules: "required"
				},
				projectValueForHydrogenEcosystem: {
					label:
						"Provide any context on how the planned project fits the IPCEI criteria.- (mainly in the area of R&D – the component should be beyond global state of art in the sector, cooperation among businesses across Member States, positive  spill over effects throughout EU)",
					rules: "required"
				},
				plannedGoals: {
					label:
						"Quantitative targets high level indicative time plan (milestones) for the implementation of the project"
				},

				//Technology readiness level
				beforeTRL1: {
					type: "checkbox"
				},
				afterTRL1: {
					type: "checkbox"
				},
				beforeTRL2: {
					type: "checkbox"
				},
				afterTRL2: {
					type: "checkbox"
				},
				beforeTRL3: {
					type: "checkbox"
				},
				afterTRL3: {
					type: "checkbox"
				},
				beforeTRL4: {
					type: "checkbox"
				},
				afterTRL4: {
					type: "checkbox"
				},
				beforeTRL5: {
					type: "checkbox"
				},
				afterTRL5: {
					type: "checkbox"
				},
				beforeTRL6: {
					type: "checkbox"
				},
				afterTRL6: {
					type: "checkbox"
				},
				beforeTRL7: {
					type: "checkbox"
				},
				afterTRL7: {
					type: "checkbox"
				},
				beforeTRL8: {
					type: "checkbox"
				},
				afterTRL8: {
					type: "checkbox"
				},
				beforeTRL9: {
					type: "checkbox"
				},
				afterTRL9: {
					type: "checkbox"
				},
				projectLooksForPartnersFrom: {
					label:
						"Our project searches partners for...(Explain what partners you are looking for (companies, research organisations etc.) and in what industry or field of study)",
					rules: "required"
				},
				projectEnablesPartnersTo: {
					label: "Our project offers to partners...(Explain the spill-overs that you can offer to other partners)",
					rules: "required"
				},
				projectNeedsStatesHelp: {
					label:
						"Why can the project not be implemented already and or without State aid? Technical challenges/uncertainties/regulatory uncertainties/commercial uncertainties/lack of capital/other (please specify)",
					rules: "required"
				},
				projectAssumptions: {
					label: "Prerequisites (Which inputs, events, regulations need to happen so the project could be implemented)",
					rules: "required"
				}
			}
		}
	}
}
