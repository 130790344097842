import {ApiCall} from "../../global/helpers/api"
import {getUser} from "../helpers/actions"
import config from "../../global/config/settings"

export default {
	login(recaptchaToken, login, password) {
		return new ApiCall({
			customURL: `${config.API_PUBLIC_URL}/sec/login?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: {
				login,
				password
			},
			includeToken: false
		})
	},

	registration(req, recaptchaToken) {
		return new ApiCall({
			path: `-pub/client/register?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	registrationFinish(req, token, recaptchaToken) {
		return new ApiCall({
			path: `-pub/client/action-consume/${token}?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	resetPassword(req, recaptchaToken) {
		return new ApiCall({
			path: `-pub/client/reset-password?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	logout() {
		return new ApiCall({
			path: "/sec/logout",
			method: "POST"
		})
	},

	saveRequest(req) {
		return new ApiCall(`/inn/record/proxy`, "PUT", req)
	},
	loadRequest(id) {
		return new ApiCall(`/ipcei/record/proxy/${id}?level_flag=CDL4`, "GET")
	},
	loadRequestWithAtt(id) {
		return new ApiCall(`/inn/record/proxy/${id}?level_flag=CDL4&inclatt=1`, "GET")
	},
	loadPrintRequest(id) {
		return new ApiCall(`/ipcei/record/proxy/${id}?level_flag=CDL5&inclatt=1`, "GET")
	},
	saveCompany(req) {
		return new ApiCall(`/entity/entities`, "PUT", req)
	},
	getEntity(personId) {
		return new ApiCall(`/entity/entities/${personId}?level_flag=CDL3`, "GET")
	},
	saveAttachment(data) {
		return new ApiCall("/object/attachment", "PUT", data)
	},
	saveAttachmentContent(id, content) {
		// return new ApiCall(`/object/attachment/${id}/content`,"POST",content)
		return new ApiCall({
			path: `/object/attachment/${id}/content`,
			method: "POST",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			body: content,
			transform: false
		})
	},
	getRequests(req) {
		return new ApiCall(`/inn/record/overview?level_flag=CDL4`, "POST", req)
	},

	loadEntityCity(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.City`, "POST", filter)
	},

	loadAttachmentData(link) {
		return new ApiCall(`${link}`, "GET")
	},

	getEcho() {
		return new ApiCall(`/discovery/echo`, "GET")
	},

	loadAttachmentTemplates() {
		return new ApiCall(`/ipcei/record/cl/IPCEI.CL.Record.RecordType/IPCEI.Data.Request/attachments`, "GET")
	},

	downloadFile(path, fileName) {
		const user = getUser()
		fetch(`${config.API_BASE_URL}${path}`, {
			method: "GET",
			mode: "cors",
			headers: {
				Authorization: `SIEA ${user.sieatoken || ""}`
			}
		})
			.then((response) => {
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName || "file")
				})
			})
			.catch(() => {})
		// return new ApiCall(`${path}`, "GET");
	},

	loadCalls() {
		return new ApiCall(`/inn/record/calls`, "POST")
	},

	exportToXLS(data) {
		return new ApiCall(`/object/exportfilled`, "POST", data)
	},

	loadCompanyInfo(id) {
		return new ApiCall(`/entity/entities/${id}?level_flag=CDL3`, "GET")
	},

	loadSolverRequests(filter) {
		return new ApiCall(`/inn/record/solver/overview`, "POST", filter)
	},

	regionRestriction(req) {
		return new ApiCall(`/inn/record/regionrestriction`, "POST", req)
	}
}
