"use strict"

import codeListApi from "../../global/config/api"
import {observable, action} from "mobx"
import {get, set} from "idb-keyval"
import {codeListsConfig} from "../../global/config/codeLists"
import GlobaStore from "../../global/store/GlobalStore"
// import {toJS} from "mobx"

class RegistersCacheStore {
	@observable registersLoaded = true
	registersLoading = false

	@action async loadCodelists() {
		const codelistsFromStorage = await get("codeLists")
		let versions = await get("versions")

		if (isSafe(codelistsFromStorage) && isSafe(versions) && isSafe(versions.codelist)) {
			let versionsObj = {}
			const codelistVersions = versions.codelist
			GlobaStore.CL = codelistsFromStorage
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let codelistResponse = await codeListApi.loadCodeList(cl.uri, "POST", {version: codelistVersions[cl.name]})
				if (isSafe(codelistResponse) && isSafe(codelistResponse.rows)) {
					GlobaStore.CL[cl.name] = codelistResponse.rows
					versionsObj[cl.name] = codelistResponse.version
				} else {
					versionsObj[cl.name] = codelistVersions[cl.name]
				}
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = {codelist: versionsObj}
			}

			// await set("codeLists", toJS(GlobaStore.CL))
			// await set("versions", versions)
		} else {
			let versionsObj = {}
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let val = await codeListApi.loadCodeList(cl.uri)

				GlobaStore.CL[cl.name] = val.rows
				versionsObj[cl.name] = val.version
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = {codelist: versionsObj}
			}

			// await set("codeLists", toJS(GlobaStore.CL))
			// await set("versions", versions)
		}
	}

	@action async checkRegistersTest() {
		let promise = new Promise((resolve) => {
			setTimeout(() => resolve(true), 2000)
		})
		let result = await promise
		return result
	}

	//Kontrola ci su loadnute v store data, v pripade ze je uzivatel na login obrazovke vymaze sa obsah IndexedDB a po logine zbehne kontrola a loadnu sa data z BE
	@action async checkRegisters(route) {
		if (route != "/" && route != "/login" && route != "/registerFinish" && route != "/passwordResetFinish") {
			if (!this.registersLoading) {
				this.registersLoading = true
				this.registersLoaded = false

				// if (Object.keys(GlobaStore.CL).length == 0) {
				//Load vsetkych CL do IndexedDB
				await this.loadCodelists()
				// }

				this.registersLoading = false
				this.registersLoaded = true
			}
		}
	}

	//Vymaze ciselniky ktore nemaju verzie a teda musia byt resetovane po kazdom odhlaseni
	@action async clearRegisters() {
		this.registersLoading = true
		this.insurersRegister = []
		await set("insurers", null)
		this.measureSetRegister = []
		await set("measureSet", null)
		this.registersLoading = false
	}
}
var singleton = new RegistersCacheStore()
export default singleton
