"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"
import moment from "moment"
import {injectIntl} from "react-intl"
import {Container, Box, Grid, Typography} from "@material-ui/core"
// import XsButton from "../../global/ui/xsButton/xsButton"
import XsTable from "../../global/ui/xsTable/xsTable"
import RequestStore from "./requestStore"
// import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"

const columns = [
	{
		Header: "Dátum odoslania žiadosti",
		width: 150,
		accessor: "sent_date_time",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.sent_date_time) ? rowA.original.sent_date_time : null
			const send_date_time_B = isNotEmpty(rowB.original.sent_date_time) ? rowB.original.send_date_time : null

			if (isNotSafe(send_date_time_A) && isNotSafe(send_date_time_B)) {
				return 0
			}
			if (isNotSafe(send_date_time_A)) {
				return -1
			}

			if (isNotSafe(send_date_time_B)) {
				return 1
			}

			return moment(send_date_time_A).diff(moment(send_date_time_B))
		},
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: "Číslo žiadosti",
		width: 120,
		accessor: "innovation_request_id"
	},
	{
		Header: "Kód výzvy",
		width: 120,
		accessor: "call.call_type.code_ext"
	},
	// {
	// 	Header: "Názov projektu",
	// 	width: 120,
	// 	accessor: "project_name"
	// },
	// {
	// 	Header: "Výška inovačnej poukážky",
	// 	width: 120,
	// 	accessor: "innovation_voucher_amount"
	// },
	{
		Header: "Stav žiadosti",
		width: 120,
		accessor: "status.name_ext"
	}
]

function Requests() {
	useEffect(() => {
		RequestStore.getRequests()
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							Môj zoznam žiadostí
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Box pt={2}>
				<Grid container>
					{/* <Grid item xs={12} container justify="flex-end">
						<Grid item>
							<XsButton
								disabled={GlobalStore.isRegistrationClose()}
								text="New IPCEI Registration form (application)"
								icon={<i className="fas fa-plus"></i>}
								onClick={() => RouterStore.push("/request")}
							/>
						</Grid>
					</Grid> */}
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={RequestStore.data}
								onRowClick={(row) => RouterStore.push(`/request?id=${row.record_id}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(Requests))

