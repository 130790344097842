export default {
	DEBUG: process.env.DEBUG != "0" || false,
	SERVERROLE: process.env.SERVERROLE || "PROD",
	API_BASE_URL: process.env.API_BASE_URL,
	API_PUBLIC_URL: process.env.API_PUBLIC_URL,
	SITE_KEY: process.env.SITE_KEY,
	API_LENGTH_THRESHOLD: 1048576,
	BROWSER_LOCALE: "sk-SK",
	HOMEPAGE: process.env.HOMEPAGE || "/",
	MPDF_API: process.env.MPDF_API,
	DB_DATETIME_FORMAT: process.env.DB_DATETIME_FORMAT || "YYYY-MM-DDTHH:mm:ssZ",
	DB_DATE_FORMAT: process.env.DB_DATE_FORMAT || "YYYY-MM-DD",
	API_CLIENT_TIMEOUT: 120000
}
