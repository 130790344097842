"use strict"

import {observable, action} from "mobx"
import api from "../config/api"

class GlobalStore {
	@observable isLoading = false
	@observable loadingMessage = ""

	@observable CL = {}
	@observable confirmationDialogOpened = undefined
	@observable authentificationFailed = false

	confirmationDialogParams = {}

	@observable notification = {message: "", data: "", variant: "default"}

	BLConfiguration = undefined
	@observable BLConfigurationLoaded = false

	defaultFocusFields = {}

	// registrationCloseIn = new Date("2021-01-21T15:00:00")
	registrationCloseIn = new Date("2030-03-22T12:00:00")
	@observable countdownExpireToken = ""

	@action isRegistrationClose() {
		if (this.registrationCloseIn < new Date()) {
			return true
		}

		return false
	}

	@action loading(isLoading, message = "") {
		this.isLoading = isLoading
		this.loadingMessage = message
	}

	@action handleClickFocus(formName, isTextArea = false) {
		if (isTextArea) {
			if (isSafe(this.defaultFocusFields[formName]) && isSafe(this.defaultFocusFields[formName].reactQuillRef)) {
				this.defaultFocusFields[formName].reactQuillRef.focus()
			}
		} else {
			if (isSafe(this.defaultFocusFields[formName])) {
				this.defaultFocusFields[formName].focus()
			}
		}
	}

	@action GetBLConfiguration() {
		if (isNotSafe(this.BLConfiguration)) {
			api.loadTypeRoutes().then((c) => ((this.BLConfiguration = c), (this.BLConfigurationLoaded = true)))
		}
	}

	@action openConfirmationDialog(dialogName, params) {
		this.confirmationDialogParams = params
		this.confirmationDialogOpened = dialogName
	}

	@action closeConfirmationDialog() {
		this.confirmationDialogParams = {}
		this.confirmationDialogOpened = undefined
	}

	setNotificationMessage(message, data = "", variant) {
		if (isNotEmpty(message)) {
			this.notification = {
				message: message,
				data: data,
				variant: variant
			}
		}
	}
}

var singleton = new GlobalStore()
export default singleton
