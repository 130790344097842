import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import "./xsDialog.less"

import XsButton from "../xsButton/xsButton"

import GlobalStore from "../../store/GlobalStore"

export default observer(
	({
		name,
		title,
		/*titleIcon,*/ subTitle,
		text,
		type,
		onConfirmation,
		confirmBtn,
		cancelBtn,
		onCancel,
		hideCancel = false
	}) => {
		const handleClose = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleDecline = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleConfirm = (e) => {
			if (isSafe(onConfirmation) && typeof onConfirmation === "function") {
				onConfirmation(e)
			}
			GlobalStore.closeConfirmationDialog()
		}

		let cancelBtnClassName = isNotEmpty(type) ? `xs-${type}` : ""
		let confirmBtnClassName = isNotEmpty(type) ? `xs-${type}` : ""

		if (isSafe(cancelBtn)) {
			if (isNotEmpty(cancelBtn.type)) {
				cancelBtnClassName = `xs-${cancelBtn.type} `
			}

			if (isNotEmpty(cancelBtn.variant)) {
				switch (cancelBtn.variant) {
					case "outlined":
						cancelBtnClassName += `xs-outlined `
						break
					case "text":
						cancelBtnClassName += `xs-text `
						break
				}
			}
		}

		if (isSafe(confirmBtn)) {
			if (isNotEmpty(confirmBtn.type)) {
				confirmBtnClassName = `xs-${confirmBtn.type} `
			}

			if (isNotEmpty(confirmBtn.variant)) {
				switch (confirmBtn.variant) {
					case "outlined":
						confirmBtnClassName += `xs-outlined `
						break
					case "text":
						confirmBtnClassName += `xs-text `
						break
				}
			}
		}

		const dialog =
			name === GlobalStore.confirmationDialogOpened ? (
				<Dialog
					open={true}
					onEscapeKeyDown={() => handleClose()}
					maxWidth="md"
					className={`xs-confirmation-dialog ${name}`}
				>
					<DialogTitle className={isNotEmpty(type) ? `xs-title xs-title-${type}` : "xs-title"}>
						<Grid container direction="column">
							{/* {isSafe(titleIcon) ? (
								<Grid item>{titleIcon}</Grid>
							) : (
								<Grid item>
									<i className="fal fa-exclamation-triangle fa-2x"></i>
								</Grid>
							)} */}
							{isSafe(title) ? (
								<Grid item>
									<Typography variant="h6">{title}</Typography>
								</Grid>
							) : (
								<Grid item>
									<Typography variant="h6">
										<FormattedMessage id="Common.label.warning" />
									</Typography>
								</Grid>
							)}
							{isSafe(subTitle) && (
								<Typography variant="body2">
									<FormattedMessage id={subTitle} />
								</Typography>
							)}
						</Grid>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Typography variant="body2">{text}</Typography>
					</DialogContent>
					<DialogActions className="xs-footer">
						<Grid container spacing={1} justify="flex-end">
							{isSafe(cancelBtn) && isSafe(cancelBtn.text) ? (
								<Grid item>
									<XsButton
										className={cancelBtnClassName}
										icon={isSafe(cancelBtn.icon) ? cancelBtn.icon : null}
										text={<FormattedMessage id={cancelBtn.text} />}
										onClick={() => handleDecline()}
									/>
								</Grid>
							) : (
								<Grid item>
									{!hideCancel && (
										<XsButton
											icon={<i className="fal fa-times" />}
											text={<FormattedMessage id="Common.btn.no" />}
											onClick={() => handleDecline()}
										/>
									)}
								</Grid>
							)}
							{isSafe(confirmBtn) && isSafe(confirmBtn.text) ? (
								<Grid item>
									<XsButton
										className={confirmBtnClassName}
										icon={isSafe(confirmBtn.icon) ? confirmBtn.icon : null}
										text={<FormattedMessage id={confirmBtn.text} />}
										onClick={(e) => handleConfirm(e)}
									/>
								</Grid>
							) : (
								<Grid item>
									<XsButton
										icon={<i className="fal fa-check" />}
										className="xs-success action-confirmation-confirm"
										text={<FormattedMessage id="Common.btn.yes" />}
										onClick={() => handleConfirm()}
									/>
								</Grid>
							)}
						</Grid>
					</DialogActions>
				</Dialog>
			) : null

		return dialog
	}
)

